<template>
  <form @submit.prevent>
    <h1>Оставить заявку</h1>
    <p>Оставьте контактную информацию и наш эксперт
      свяжется с Вами в ближайшее время.</p>

    <div class="form-control">
      <label>Укажите Ваше имя</label>
      <input
          required
          v-model="name"
          class="input-lg"
          :class="{'error':this.check && !this.name}"
          type="text"
          placeholder="Иванов Иван">
    </div>

    <div class="form-control">
      <label>Укажите Ваш номер</label>
      <input
          required
          v-model="phone"
          class="input-lg"
          :class="{'error':this.check && !this.phone}"
          type="text"
          placeholder="+7 900 123 1234">
    </div>


    <div v-show="linksShow" class="form-control">
      <label>Укажите ссылку на объявление</label>
      <input
          required
          v-model='links'
          class="input-lg"
          type="text"
          :class="{'error':this.linksShow && this.check && !this.links}"
          placeholder="https://auto.com/cars/hyundai/654">
    </div>

    <div v-show="descriptionShow" class="form-control">
      <label>Комментарий</label>
      <textarea
          name="adLink"
          class="textarea"
          rows="3"
          v-model='description'
          placeholder="Опишите, какой автомобиль вы бы хотели"></textarea>
    </div>

    <div style="margin-top:16px; display: flex; gap:24px; justify-content: center;">
      В каком мессанджере Вам удобнее будет общаться?
    </div>
    <div style="margin-top:16px; display: flex; gap:24px;justify-content: center;"  :class="{'error':!this.mainMessenger && this.check}">
      <div class="checkbox">
        <input v-model="mainMessenger" class="custom-checkbox" type="radio" :id="'checkbox-telegram-'+$.uid" value="telegram">
        <label :for="'checkbox-telegram-'+$.uid">Telegram</label>
      </div>

      <div class="checkbox">
        <input v-model="mainMessenger" class="custom-checkbox" type="radio" :id="'checkbox-viber-'+$.uid" value="viber">
        <label :for="'checkbox-viber-'+$.uid">Viber</label>
      </div>

      <div class="checkbox">
        <input v-model="mainMessenger" class="custom-checkbox" type="radio" :id="'checkbox-whatsup-'+$.uid" value="whatsup">
        <label :for="'checkbox-whatsup-'+$.uid">WhatsUp</label>
      </div>
    </div>


    <div class="tingle-modal-box__footer text-center">
      <button @click="handleClick" class="btn btn--uppercase btn--lg btn--blue">Отправить заявку</button>
    </div>

  </form>
</template>

<script>
import {Modal} from "jenesius-vue-modal";
export default {
  props: {
    linksShow: {
      type: Boolean,
      default: false
    },
    descriptionShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      id: null,
      check: false,
      name: '',
      phone: '',
      mainMessenger: '',
      links: '',
      description: '',
    }
  },
  methods: {
    checkForm: function (e) {
      if (this.name && this.phone && this.mainMessenger) {
        return true;
      }
      return false;
    },

    handleClick() {

      this.check = true;
      if (this.checkForm()) {
        let data = {
          name: this.name,
          phone: this.phone,
          main_messenger: this.mainMessenger,
          links: this.links,
          description: this.description,
        };
        this.$emit(Modal.EVENT_PROMPT, data);
      } else{
        console.log(this.name);
        console.log(this.mainMessenger);
        console.log(this.phone);
        console.log(this.description);
        console.log('Check failed')
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  border-color: #dc3545;
  color:#dc3545;
}
</style>