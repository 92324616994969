<template>
  <div>
    <div class="mobile_menu_container">
      <div class="mobile_menu_content">
        <div class="logo">
          <a href="#">
            <img src="/dist/images/logo.png" alt="CarPickUp - Онлайн-сервис оценки и подбора автомобилей с пробегом"
                 class="header__logo-image">
            <p>проверка и подбор авто</p>
          </a>
        </div>
        <ul class="menu">
          <li><button @click="showPrices" class="header__link">Цены</button></li>
          <li v-for="menuItem in headerMenu" :key="menuItem.id"><a :href="menuItem.href" v-html="menuItem.title" class="header__link"></a></li>
        </ul>
      </div>
    </div>
    <div class="mobile_menu_overlay">
      <div id="close"><img src="/dist/images/x.svg" alt=""></div>
    </div>
  </div>
</template>

<script>
import {closeModal, container, openModal, promptModal} from "jenesius-vue-modal";
import PricesForm from "../modals/prices.vue";
import axios from "axios";
import {API_URL} from "../app";
export default {
  props: {
    headerMenu: {
      type: Object,
      default: {}
    },
  },
  mounted() {
    console.log(this.headerMenu)
  },
  methods: {
    async showPrices() {
      const code = await promptModal(PricesForm);
      const view = document.getElementById("whatwecheck");
      const selection = document.getElementById("carselection");
      if (code.service === 1) {
        this.$smoothScroll({
          scrollTo: selection, // scrollTo is also allowed to be number
        })
      }
      if (code.service === 2) {
        this.$smoothScroll({
          scrollTo: view, // scrollTo is also allowed to be number
        })
      }
    },

  },
}
</script>

<style lang="scss" scoped>

</style>