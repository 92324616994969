<template>
  <input ref="inputRef" type="text"/>
</template>

<script>
import {useCurrencyInput} from "vue-currency-input";
import {watchDebounced} from "@vueuse/core";

export default {
  name: "DebouncedCurrencyInput",
  props: {
    options: Object,
  },
  setup(props, {emit}) {
    const {inputRef, numberValue} = useCurrencyInput(props.options, true);

    watchDebounced(numberValue, (value) => emit("update:modelValue", value), {
      debounce: 1000,
    });

    return {inputRef};
  },
};
</script>