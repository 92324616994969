<template>
  <div id="checkedcars" class="checkedcars-container">
    <div class="container">
      <section class="checkedcars">
        <h1 class="text-center" data-aos="fade-up">ОСМОТРЕННЫЕ АВТО</h1>

        <div class="swiper-container checkedcars__swiper-container">
          <swiper
              :modules="modules"
              :slides-per-view="1"
              navigation
              :pagination="{ clickable: true }"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :space-between="100"
              class="checkedcars__swiper"
              data-aos="fade-up"
              :loop="true"
          >
            <swiper-slide class="checkedcars__slide" v-for="item in viewedCars" :key="item.id">
              <div class="grid grid-2">
                <div class="checkedcars__slide-images">
                  <img :src="item.image" :alt="item.title">
                </div>
                <div class="checkedcars__slide-info">
                  <h3 class="title">{{ item.title }}</h3>
                  <p class="subtitle"></p>

                  <div class="params">
                    <div class="title">Параметры подбора</div>
                    <div class="body">{{ item.description }}</div>
                  </div>

                  <div class="result">
                    <div class="title">Результат</div>
                    <div class="body">{{ item.result_description }}</div>
                  </div>
                </div>
              </div>
            </swiper-slide>
          </swiper>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
import {API_URL} from "../app";
// import Swiper core and required modules
import {Navigation, Pagination, Scrollbar, A11y} from 'swiper/modules';

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import axios from "axios";

import LiteYouTubeEmbed from 'vue-lite-youtube-embed'

export default {
  components: {
    Swiper,
    SwiperSlide,
    LiteYouTubeEmbed
  },
  setup() {
    const onSwiper = (swiper) => {
      // console.log(swiper);
    };
    const onSlideChange = () => {
      // console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },

  data() {
    return {
      viewedCars: []
    }
  },

  beforeCreate() {

    axios({
      method: 'get',
      url: API_URL + '/viewed-cars',
    }).then((response) => {
      this.viewedCars = response.data;
    })
  },
  methods: {
    getYoutubeId(url) {
      let parts = url.split("/");
      return parts[parts.length - 1];
    }
  },
}
</script>
