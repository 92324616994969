<template>
  <div class="ourservice-container">
    <div class="container">
      <section id="ourservice" class="ourservice">
        <div class="ourservice__header">
          <h1 class="ourservice__title" data-aos="fade-up">наши услуги</h1>
        </div>
        <div class="ourservice__body grid grid-2 gap-3">
          <div class="ourservice__item" data-aos="fade-right" data-aos-offset="300">
            <h2 class="ourservice__item-title">Разовый осмотр</h2>
            <div class="ourservice__item-body">
              <p> Выездная проверка выбранного вами транспортного средства.</p>
              <p> По итогам Осмотра вы получите подробный отчет с фотоматериалами о состоянии автомобиля,
                включая техническую и юридическую стороны, а также рекомендации к покупке автомобиля или
                отказе от нее.</p>

              <p>В случае покупки проверенного автомобиля, мы можем составить договор купли-продажи, а
                также
                присутствовать на сделке, сопровождая ее и контролируя все ее аспекты.</p>

              <p>Также вы можете заказать осмотр нового автомобиля, чтобы убедится в отсутствии у него
                скрытых дефектов</p>


            </div>
            <div class="ourservice__offer">
              <!--                            <div class="ourservice__offer-title">-->
              <!--                                <small class="pink">Разовый осмотр</small>-->
              <!--                            </div>-->
              <!--                            <div class="ourservice__offer-price">-->
              <!--                                <div class="ourservice__new-price">от 1900 ₽</div>-->
              <!--                            </div>-->
              <div class="ourservice__offer-request">
                <form @submit.prevent="onSubmit(PricesCarViewForm)">
                  <button type="submit" class="btn  btn--uppercase btn--lg btn--pink btn-pink"><span>Сколько стоит ?</span></button>
                </form>
              </div>
            </div>
          </div>
          <div class="ourservice__item" data-aos="fade-left" data-aos-offset="300">
            <h2 class="ourservice__item-title">Подбор автомобиля
              по заданным параметрам</h2>
            <div class="ourservice__item-body">
              Поиск транспортного средства по заданным вами параметрам, включающий в себя:
              <ul>
                <li>первичную оценку объявлений о продаже автомобилей;</li>
                <li>осмотры выбранных вами вариантов с предоставлением подробных отчетов с
                  фотоматериалами и
                  рекомендацией к покупке автомобиля или отказе от нее;
                </li>
                <li>сопровождение сделки по покупке транспортного средства.</li>

              </ul>


              <p>
                Весь процесс подбора вы cможете дистанционно контролировать через личный кабинет, не
                участвуя в
                нем лично.
              </p>
              <p>Ваше личное присутствие потребуется только на сделке по покупке выбранного вами
                автомобиля.</p>


            </div>
            <div class="ourservice__offer">
              <!--                            <div class="ourservice__offer-title">-->
              <!--                                <small class="pink">Подбор автомобиля по заданным параметрам</small>-->
              <!--                            </div>-->
              <!--                            <div class="ourservice__offer-price">-->
              <!--                                <div class="ourservice__new-price">от 30 000 ₽</div>-->
              <!--                            </div>-->
              <div class="ourservice__offer-request">
                <form @submit.prevent="onSubmit(PricesCarSelectionForm)">
                  <button type="submit" class="btn  btn--uppercase btn--lg btn--pink btn-pink"><span>Сколько стоит ?</span></button>
                </form>
              </div>
            </div>
          </div>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
import CurrencyInput from "../components/CurrencyInput.vue"
import {container, promptModal, openModal, closeModal} from "jenesius-vue-modal";
import PricesCarViewForm from "../modals/prices_car_view.vue"
import PricesCarSelectionForm from "../modals/prices_car_selection.vue"
import Thanks from "../modals/thanks.vue"
import axios from "axios";
import {API_URL} from "../app";
export default {
  components: {
    CurrencyInput,
    PricesCarViewForm, PricesCarSelectionForm, Thanks,
  },
  data() {
    return {
      PricesCarViewForm: PricesCarViewForm,
      PricesCarSelectionForm: PricesCarSelectionForm,
    }
  },
  methods: {
    async onSubmit(component) {
      const code = await promptModal(component);
      const view = document.getElementById("whatwecheck");
      const selection = document.getElementById("carselection");
      if (code.service === 1) {
        this.$smoothScroll({
          scrollTo: selection, // scrollTo is also allowed to be number
        })
      }
      if (code.service === 2) {
        this.$smoothScroll({
          scrollTo: view, // scrollTo is also allowed to be number
        })
      }
    },


    async sendData(url, data, method) {

      return axios({
        method: method,
        url: API_URL + url,
        data: data
      }).then((response) => {
        return response;
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>