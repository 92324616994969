<template>
  <div class="stat-container">
    <div class="container">
      <section id="stat" class="stat">
        <h1 class="text-center" data-aos="fade-up">Немного в цифрах</h1>
        <div class="stat__blocks">
          <div class="stat__block" data-aos="zoom-in-up" v-for="stat in stats" :key="stat.id">
            <div class="stat__block_digit">{{ stat.digit }}</div>
            <div class="stat__block_split"></div>
            <div class="stat__block_description" v-html="stat.description"></div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stats: [
        {digit: '6 лет', description: 'подбираем авто<br>для вас'},
        {digit: '3100+', description: 'проверенных<br>авто'},
        {digit: '700+', description: 'подобранных<br> авто'},
        {digit: '131', description: 'параметров<br>проверки'},
      ]
    }
  },
}
</script>