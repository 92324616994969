<template>
  <div class="whoweare-container">
    <div class="container">
      <section id="whoweare" class="whoweare" data-aos="fade-up" data-aos-offset="100">
        <div class="left" data-aos="fade-right">
          <LiteYouTubeEmbed id="JQLIjXlT_UY" />
        </div>
        <div class="right" data-aos="fade-left">
          <h2>
            <span class="carpickup"></span>
            - это Онлайн-сервис оценки и подбора автомобилей с пробегом
          </h2>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import LiteYouTubeEmbed from "vue-lite-youtube-embed";

export default {
  components: {LiteYouTubeEmbed},
}
</script>

<style lang="scss" scoped>

</style>