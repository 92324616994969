<template>
  <form @submit.prevent>
    <h1>Форма обратной связи</h1>
    <p>Оставьте контактную информацию и  мы свяжется с Вами в ближайшее время.</p>

    <div class="form-control">
      <label>Укажите Ваше имя</label>
      <input
          required
          pattern="[А-я]{2,100}"
          title="Проверьте Ваше имя, могут быть только буквы"
          v-model="name"
          class="input-lg"
          :class="{'error':this.check && !this.name}"
          type="text"
          placeholder="Иванов Иван">
    </div>

    <div class="form-control">
      <label>Укажите Ваш номер</label>
      <input
          required
          v-model="phone"
          class="input-lg"
          :class="{'error':this.check && !this.name}"
          type="text"
          placeholder="+7 900 123 1234">
    </div>

    <div v-show="descriptionShow" class="form-control">
      <label>Комментарий</label>
      <textarea
          name="adLink"
          class="textarea"
          rows="3"
          v-model='description'
          :class="{'error':this.descriptionShow && this.check && !this.links}"
          placeholder=""></textarea>
    </div>
<br>
    <div class="tingle-modal-box__footer text-center">
      <button @click="handleClick" class="btn btn--uppercase btn--lg btn--blue">Отправить</button>
    </div>

  </form>
</template>

<script>
import {Modal} from "jenesius-vue-modal";
import {API_URL} from "../app";
export default {
  props: {
    linksShow: {
      type: Boolean,
      default: false
    },
    descriptionShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      id: null,
      check: false,
      name: '',
      phone: '',
      description: '',
    }
  },
  methods: {
    checkForm: function (e) {
      if (this.name && this.phone) {
        return true;
      }
    },

    handleClick() {

      this.check = true;
      if (this.checkForm()) {
        let data = {
          name: this.name,
          phone: this.phone,
          description: this.description,
        };
        this.$emit(Modal.EVENT_PROMPT, data);
      } else {
        console.log('Check failed')
      }

    }
  }
}
</script>

<style lang="scss" scoped>
.error {
  border-color: #dc3545;
  color: #dc3545;
}
</style>