<template>
  <div class="whatwecheck-container">
    <div class="container">
      <section id="whatwecheck" class="whatwecheck">
        <h1 class="text-center" data-aos="fade-up">Что мы проверяем при осмотре?</h1>
        <div class="whatwecheck__body">
          <div class="left" data-aos="fade-right">
            <div class="whatwecheck__info-block">
              <div class="whatwecheck__tech">
                <h2 class="whatwecheck__title">
                  <img src="dist/images/icon_tech.png" alt="Техническая часть">
                  Техническая часть
                </h2>
                <div class="whatwecheck__check-list">
                  <div class="whatwecheck__list-item" v-for="item in checkListTech" :key="item.id">
                    <div class="whatwecheck__item-icon">
                      <img :src="item.icon" :alt="item.title">
                    </div>
                    <div class="whatwecheck__item-name">{{ item.title }}</div>
                  </div>
                </div>
              </div>
              <div class="whatwecheck__jur">
                <h2 class="whatwecheck__title">
                  <img src="dist/images/icon_jurist.png" alt="Юридическая часть">
                  Юридическая часть
                </h2>
                <div class="whatwecheck__check-list">
                  <div class="whatwecheck__list-item" v-for="item in checkListLaw" :key="item.id">
                    <div class="whatwecheck__item-icon">
                      <img :src="item.icon" :alt="item.title">
                    </div>
                    <div class="whatwecheck__item-name">{{ item.title }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="whatwecheck__offer">
              <div class="whatwecheck__offer-title">Базовый осмотр *</div>
              <div class="whatwecheck__offer-price">
                <div class="whatwecheck__old-price">{{ oldPrice }} ₽</div>
                <div class="whatwecheck__new-price">{{ newPrice }} ₽</div>
              </div>

              <ul class="whatwecheck__offer-request">
                <li>
                  <form @submit.prevent="onSubmit">
                    <button type="submit" class="btn btn--uppercase btn--lg btn--blue"><span>Закажите разовый осмотр авто</span></button>
                  </form>
                </li>
                <li>
                  <a @click="$emit('my-event')" @click.prevent class="faq-link" href="#">Часто задаваемые вопросы</a>
                </li>
              </ul>

            </div>
          </div>
          <div class="right" data-aos="fade-left">
            <div class="whatwecheck__info-block">
              <div id="guaranty" class="whatwecheck__guaranty">
                <h2 class="whatwecheck__title pink">Гарантия</h2>
                <div class="whatwecheck__body ">
                  <p> Мы гарантируем, что состояние автомобиля на момент сделки в точности соответствует предоставленному отчету.</p>

                  <p> Транспортное средство исправно, не имеет дефектов, препятствующих его безопасной эксплуатации,
                    а также ограничений, напрямую или косвенно влияющих на
                    возможность его приобретения и последующей регистрации в ГИБДД.</p>

                  <p>Если на момент покупки будут обнаружены дефекты, не указанные в отчете,
                    мы гарантируем возврат суммы, равной стоимости их устранения в рамках условий гарантии
                  </p>

                </div>
              </div>

            </div>
            <div class="whatwecheck__car" data-aos="fade-left"></div>
          </div>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
import {container, promptModal, openModal, closeModal, container as WidgetContainerModal} from "jenesius-vue-modal";
import RequestForm from "../modals/request.vue"
import Thanks from "../modals/thanks.vue"
import axios from "axios";
import {API_URL} from "../app";


export default {
  components: {
    RequestForm, Thanks
  },
  data() {
    return {
      oldPrice: 5900,
      newPrice: 3900,
      checkListTech: [
        {icon: 'dist/images/icon_kuzov.png', title: 'Проверка кузова автомобиля *'},
        {icon: 'dist/images/icon_electric.png', title: 'Электрика и электроника'},
        {icon: 'dist/images/icon_engine.png', title: 'Двигатель и навесное оборудование'},
        {icon: 'dist/images/icon_testdrive.png', title: 'Тестовая поездка'},
        {icon: 'dist/images/icon_transmission.png', title: 'КПП и трансмиссия'},
        {icon: 'dist/images/icon_airbag.png', title: 'Системы безопасности'},
        {icon: 'dist/images/icon_brake.png', title: 'Ходовая часть и тормоза'},
        {icon: 'dist/images/icon_diagnostic.png', title: 'Компьютерная диагностика'},
        {icon: 'dist/images/icon_wheel.png', title: 'Рулевое управление'},
        {icon: 'dist/images/icon_disks.png', title: 'Шины и диски'},
      ],
      checkListLaw: [
        {icon: 'dist/images/icon_jur_regular.png', title: 'Идентификационные номера и таблички *'},
        {icon: 'dist/images/icon_jur_regular.png', title: 'Правоустанавливающие документы *'},
        {icon: 'dist/images/icon_jur_regular.png', title: 'Проверка по специализированным базам данных'},
        {icon: 'dist/images/icon_jur_regular.png', title: 'Проверка на кредиты, штрафы, ограничения'},
      ],
    }
  },
  methods: {
    async onSubmit() {
      const code = await promptModal(RequestForm, {linksShow: true});
      if (code) {
        let data = {
          'service_id': 2,
          'form_place': 3,
          'description': 'Запрос на разовый осмотр автомобиля',
        };
        let requestData = Object.assign({}, data, code);
        let result = await this.sendData('/order-request/create', requestData, 'POST');
        if (result.status === 200) {
          await openModal(Thanks);
          setTimeout(() => {
            closeModal();
          }, 5000)
        }
      }
    },

    async sendData(url, data, method) {

      return axios({
        method: method,
        url: API_URL + url,
        data: data
      }).then((response) => {
        return response;
      })
    }
  },
}
</script>