<template>
  <div class="faq-container">
    <h1>FAQ</h1>

    <h2>Осмотр</h2>
    <div class="faq" v-for="item in faq" :key="item.id">
      <template v-if="item.service_id===2">
        <h3 class="faq-question">{{ item.title }}</h3>
        <div class="faq-answer" v-html="item.description"></div>
      </template>
    </div>

    <h2>Подбор</h2>
    <div class="faq" v-for="item in faq" :key="item.id">
      <template v-if="item.service_id===1">
        <h3 class="faq-question">{{ item.title }}</h3>
        <div class="faq-answer" v-html="item.description"></div>
      </template>
    </div>


  </div>


</template>

<script>
import axios from "axios";
import {API_URL} from "../app";
export default {
  data() {
    return {
      faq: {}
    }
  },
  components: {
    axios,
  },
  beforeMount() {

    axios({
      method: 'get',
      url: API_URL + '/faq',
    }).then((response) => {
      this.faq = response.data;
    })
  }
}
</script>

<style lang="scss" scoped>

</style>