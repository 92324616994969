<template>
  <div id="youtube" class="youtube-container">
    <div class="container">
      <section class="youtube">
        <h1 class="title" data-aos="fade-up">Мы на <img src="dist/images/youtube.png"></h1>

        <div class="swiper-container checkedcars__swiper-container">
          <swiper
              :modules="modules"
              :slides-per-view="1"
              navigation
              :pagination="{ clickable: true }"
              @swiper="onSwiper"
              @slideChange="onSlideChange"
              :space-between="100"
              class="youtube__swiper"
              data-aos="fade-up"
              :loop="true"
          >

            <swiper-slide class="swiper-slide reviews__slide" v-for="item in youtube" :key="item.id">
              <div style="min-height: 300px; width: 85%;" >
                <LiteYouTubeEmbed :id="getYoutubeId(item.link)" />
                <div class="slide-body reviews__slide-body">
                  {{ item.title }}
                  {{ item.description }}
                </div>
              </div>
            </swiper-slide>

          </swiper>

        </div>

        <!--        <a class="btn btn&#45;&#45;uppercase btn&#45;&#45;sm btn&#45;&#45;radius btn&#45;&#45;pink mt-30"-->
        <!--           href=""><span>посмотреть все</span></a>-->
      </section>
    </div>
  </div>
</template>

<script>
import LiteYouTubeEmbed from 'vue-lite-youtube-embed'

import {Swiper, SwiperSlide} from "swiper/vue";
import axios from "axios";
import {A11y, Navigation, Pagination, Scrollbar} from "swiper/modules";
import {API_URL} from "../app";

export default {
  components: {
    Swiper,
    SwiperSlide,
    LiteYouTubeEmbed
  },
  setup() {
    const onSwiper = (swiper) => {
      // console.log(swiper);
    };
    const onSlideChange = () => {
      // console.log('slide change');
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Navigation, Pagination, Scrollbar, A11y],
    };
  },
  data() {
    return {
      youtube: []
    }
  },
  beforeMount() {

    axios({
      method: 'get',
      url: API_URL + '/youtube',
    }).then((response) => {
      this.youtube = response.data;
    })
  },
  methods: {
    getYoutubeId(url) {
      let parts = url.split("/");
      return parts[parts.length - 1];
    }
  },
}
</script>

<style lang="scss" scoped>

</style>