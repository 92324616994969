<template>
  <div class="carselection-container">
    <div class="container">
      <section id="carselection" class="carselection">
        <div class="carselection__header">
          <h1 class="carselection__title" data-aos="fade-up">
            поручите поиск автомобиля нам<br>
          </h1>
          <p data-aos="fade-up">Заполните заявку на подбор автомобиля</p>
        </div>
        <div class="carselection__body" data-aos="fade-up">
          <!--                    <h2 class="carselection__act-title">Заполните заявку на подбор автомобиля</h2>-->
          <div class="carselection__car-selection-price-block">
            <div class="carselection__car-selection-price text-uppercase">СТОИМОСТЬ подбора
              от 26 900 ₽
            </div>
<!--            <div class="carselection__car-selection-subprice text-uppercase">+ 50% ОТ СУММЫ ТОРГА</div>-->
          </div>

          <form @submit.prevent="onSubmit">
            <div class="carselection__car-selection">
              <div class="carselection__car-selection-main">
                <div class="mb-15 car-line">
                  <div class="mb-15 grid grid-3" v-for="select2component in select2components">
                    <Select2 placeholder="Бренд" v-model="carBrandSelected[select2component.id]" :options="carBrandsOptions" @select="loadModels(select2component.id)"/>
                    <Select2 placeholder="Модель" v-model="carModelSelected[select2component.id]" :options="carModelOptions[select2component.id]"/>
                    <div v-show="select2component.id === select2components[select2components.length-1].id" @click="addSelect(select2components[select2components.length-1].id +1)"
                         class="btn btn--icon">
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.2 7.72V10.28H10.08V17.4H7.52V10.28H0.4V7.72H7.52V0.6H10.08V7.72H17.2Z" fill="#808080"/>
                      </svg>
                    </div>
                    <div v-show="select2component.id !== select2components[select2components.length-1].id" @click="removeSelect(select2component.id)" class="btn btn--icon">
                      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19.4105 17.7424L17.6003 19.5526L12.5657 14.518L7.53109 19.5526L5.72089 17.7424L10.7555 12.7078L5.72089 7.67322L7.53109 5.86303L12.5657 10.8976L17.6003 5.86303L19.4105 7.67322L14.3759 12.7078L19.4105 17.7424Z"
                            fill="#f53bb9"/>
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="params">
                  <div class="mb-15 grid grid-3">
                    <div class="grid grid-2">
                      <Select2 placeholder="Тип авто" v-model="carTypeSelected" :options="carTypeOptions"/>
                      <Select2 placeholder="Тип кузова" v-model="carBodySelected" :options="carBodyOptions"/>
                    </div>
                    <div class="grid grid-2">
                      <Select2 placeholder="Двигатель" v-model="carEngineSelected" :options="carEngineOptions"/>
                      <Select2 placeholder="Коробка" v-model="carTransmissionSelected" :options="carTransmissionOptions"/>
                    </div>
                    <div class="select-group">
                      <Select2 placeholder="Объем от, л" v-model="carCapacityFromSelected" :options="carCapacityOptions"/>
                      <Select2 placeholder="до, л" v-model="carCapacityToSelected" :options="filteredCapacity()"/>
                    </div>
                    <div class="select-group">
                      <Select2 placeholder="Год от" v-model="carYearFromSelected" :options="carYearOptions" @select="filteredYears"/>
                      <Select2 placeholder="до" v-model="carYearToSelected" :options="filteredYears()"/>
                    </div>
                    <div class="grid grid-2">
                      <Select2 placeholder="Привод" v-model="carDriveSelected" :options="carDriveOptions"/>
                      <Select2 placeholder="Пробег" v-model="carMileageSelected" :options="carMileageOptions"/>
                    </div>
                    <div class="grid grid-2">
                      <Select2 placeholder="Дефекты" v-model="carDefectsSelected" :options="carDefectsOptions"/>
                      <Select2 placeholder="Цвет" v-model="carColorSelected" :options="carColorOptions"/>
                    </div>
                    <div class="grid grid-2">
                      <Select2 placeholder="Владельцев" v-model="carOwnersSelected" :options="carOwnersOptions"/>
                    </div>
                    <div class="grid grid-1">
                      <CurrencyInput
                          placeholder="Стоимость авто до, руб"
                          v-model="carPriceTo"
                          :options="{
                                      currency: 'RUB',
                                      hideCurrencySymbolOnFocus: false,
                                      hideGroupingSeparatorOnFocus: false,
                                      hideNegligibleDecimalDigitsOnFocus: false,}"
                      />
                    </div>

                    <div class="grid">
                      <button @click="carSelectionMore=!carSelectionMore" type="button" href="#" class="btn  btn--uppercase btn--sm btn--blue btn--radius">
                        <span v-show="!carSelectionMore">Показать все параметры</span>
                        <span v-show="carSelectionMore">Скрыть параметры</span>
                      </button>
                    </div>

                  </div>

                </div>

              </div>
              <div v-show="carSelectionMore" class="carselection__car-selection-more">
                <div class="advanced-features">
                  <div class="advanced-features-block">
                    <h2 class="advanced-features-title">Комфорт</h2>
                    <div class="grid grid-4">
                      <div class="checkbox" v-for="param in comfortParams" :key="param.id">
                        <input v-model="comfortParamsSelected" :value="param.id" class="custom-checkbox" type="checkbox" :id="'comfort-'+param.id">
                        <label :for="'comfort-'+param.id">{{ param.title }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="advanced-features-block">
                    <h2 class="advanced-features-title">Безопасность</h2>
                    <div class="grid grid-4">
                      <div class="checkbox" v-for="param in safetyParams" :key="param.id">
                        <input v-model="safetyParamsSelected" :value="param.id" class="custom-checkbox" type="checkbox" :id="'safety-'+param.id">
                        <label :for="'safety-'+param.id">{{ param.title }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="advanced-features-block">
                    <h2 class="advanced-features-title">Салон</h2>
                    <div class="grid grid-4">
                      <div class="checkbox" v-for="param in salonParams" :key="param.id">
                        <input v-model="salonParamsSelected" :value="param.id" class="custom-checkbox" type="checkbox" :id="'chkbox-'+param.id">
                        <label :for="'chkbox-'+param.id">{{ param.title }}</label>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

            <div class="carselection__offer-request">
              <button type="submit" class="btn  btn--uppercase btn--lg btn--pink btn-blue"><span>Отправить заявку</span></button>
              <a @click="$emit('my-event')" @click.prevent class="faq-link" href="#">Часто задаваемые вопросы</a>
            </div>
          </form>
        </div>

      </section>
    </div>
  </div>
</template>

<script>
import Select2 from 'vue3-select2-component';
import CurrencyInput from "../components/CurrencyInput.vue"
import {container, promptModal, openModal, closeModal} from "jenesius-vue-modal";
import Contact from "../modals/request.vue"
import Thanks from "../modals/thanks.vue"
import axios from "axios";
import {API_URL} from "../app";


export default {
  beforeMount() {
    // Получаем авто
    this.getCars();

    // Генерируем года
    this.yearsGenerator();
    // Генерируем объем двигателя
    this.capacityGenerator();
  },
  components: {
    Select2,
    CurrencyInput,
    Contact, Thanks
  },
  data() {
    return {

      select2components: [
        {id: 1},
      ],

      // База Брендов и Моделей
      carsBase: [],

      // Бренд
      carBrandSelected: {},
      carBrandsOptions: [],

      // Модель
      carModelSelected: {},
      carModelOptions: [],

      // Тип авто
      carTypeSelected: null,
      carTypeOptions: [
        {id: 1, text: 'Седан'},
        {id: 2, text: 'Хетчбек'},
        {id: 3, text: 'Универсал'},
        {id: 4, text: 'Лифтбек'},
        {id: 5, text: 'Купе'},
        {id: 6, text: 'Кабриолет'},
        {id: 7, text: 'Родстер'},
        {id: 8, text: 'Фургон'},
        {id: 9, text: 'Минивен'},
        {id: 10, text: 'Пикап'},
      ],

      // Тип кузова
      carBodySelected: null,
      carBodyOptions: [
        {id: 1, text: 'Легковой'},
        {id: 2, text: 'Кросовер'},
        {id: 3, text: 'Внедорожник'},
        {id: 4, text: 'Минивен'},
      ],

      // Двигатель
      carEngineSelected: null,
      carEngineOptions: [
        {id: 1, text: 'Любой'},
        {id: 2, text: 'Бензин'},
        {id: 3, text: 'Бензин Турбо'},
        {id: 4, text: 'Дизель'},
        {id: 5, text: 'Электрический'},
        {id: 6, text: 'Гибрид'},
        {id: 7, text: 'Газ'},
      ],

      // Коробка
      carTransmissionSelected: null,
      carTransmissionOptions: [
        {id: 1, text: 'Любая'},
        {id: 2, text: 'АКПП'},
        {id: 3, text: 'Вариатор'},
        {id: 4, text: 'Робот'},
        {id: 5, text: 'МКПП'},
      ],

      // Привод
      carDriveSelected: null,
      carDriveOptions: [
        {id: 1, text: 'Любой'},
        {id: 2, text: 'Задний'},
        {id: 3, text: 'Передний'},
        {id: 4, text: 'Полный'},
      ],

      // Пробег
      carMileageSelected: null,
      carMileageOptions: [
        {id: 5000, text: 'до 5 000 км'},
        {id: 15000, text: 'до 15 000 км'},
        {id: 20000, text: 'до 20 000 км'},
        {id: 35000, text: 'до 35 000 км'},
        {id: 50000, text: 'до 50 000 км'},
        {id: 75000, text: 'до 75 000 км'},
        {id: 100000, text: 'до 100 000 км'},
        {id: 150000, text: 'до 150 000 км'},
        {id: 200000, text: 'до 200 000 км'},
        {id: 500000, text: 'до 500 000 км'},
      ],

      // Владельцев
      carOwnersSelected: null,
      carOwnersOptions: [
        {id: 1, text: 'не важно'},
        {id: 2, text: '1'},
        {id: 3, text: '2'},
        {id: 4, text: 'не более 3'},
      ],

      // Цена
      carPriceTo: '',

      // Объем двигателя
      carCapacityFromSelected: null,
      carCapacityToSelected: null,
      carCapacityOptions: [],

      // Дефекты
      carDefectsSelected: null,
      carDefectsOptions: [
        {id: 1, text: 'Ремонт'},
        {id: 2, text: 'Окрас'},
        {id: 3, text: 'Сколы/царапины'},
        {id: 4, text: 'Вмятины'},
        {id: 5, text: 'Ржавчина'},
      ],

      // Цвет
      carColorSelected: null,
      carColorOptions: [
        {id: 1, text: 'Светлый'},
        {id: 2, text: 'Темный'},
        {id: 3, text: 'Белый'},
        {id: 4, text: 'Серебристый'},
        {id: 5, text: 'Бежевый'},
        {id: 6, text: 'Желтый'},
        {id: 7, text: 'Золотистый'},
        {id: 8, text: 'Оранжевый'},
        {id: 9, text: 'Розовый'},
        {id: 10, text: 'Красный'},
        {id: 11, text: 'Пурпурный'},
        {id: 12, text: 'Коричневый'},
        {id: 13, text: 'Голубой'},
        {id: 14, text: 'Синий'},
        {id: 15, text: 'Фиолетовый'},
        {id: 16, text: 'Зеленый'},
        {id: 17, text: 'Серый'},
        {id: 18, text: 'Черный'},
      ],

      // Год
      carYearFromSelected: null,
      carYearToSelected: null,
      carYearOptions: [],

      // Показать скрыть доп параметры авто
      carSelectionMore: false,

      //Комфорт
      comfortParamsSelected: [],
      comfortParams: [
        {id: 0, title: 'Кондиционер'},
        {id: 1, title: 'Климат-контроль'},
        {id: 2, title: 'Датчик дождя'},
        {id: 3, title: 'Датчик света'},
        {id: 4, title: 'Круиз-контроль'},
        {id: 5, title: 'Мультифункц. рулевое колесо'},
        {id: 6, title: 'Штатная аудиосистема'},
        {id: 7, title: 'Голосовое управление и Bluetooth'},
        {id: 8, title: 'Усилитель руля'},
        {id: 9, title: 'Электроподъемники задние'},
        {id: 10, title: 'Электроподъемники передние'},
      ],

      //Безопасность
      safetyParamsSelected: [],
      safetyParams: [
        {id: 1, title: 'ABS'},
        {id: 2, title: 'ESP'},
        {id: 3, title: 'Антипробуксовочная система'},
        {id: 4, title: 'Подушка безопасности водителя'},
        {id: 5, title: 'Подушка б. для защиты коленей'},
        {id: 6, title: 'Подушка безопасности пассажира'},
        {id: 7, title: 'Подушки безопасности боковые'},
        {id: 8, title: 'Подушки б. боковые задние'},
        {id: 9, title: 'Подушки б. оконные (шторки)'},
        {id: 10, title: 'Ксеноновые/Биксеноновые фары'},
        {id: 11, title: 'Сигнализация'},
      ],

      //Салон
      salonParamsSelected: [],
      salonParams: [
        {id: 1, title: 'Светлый салон'},
        {id: 2, title: 'Темный салон'},
        {id: 3, title: 'Комбинированный'},
        {id: 4, title: 'Ткань'},
        {id: 5, title: 'Кожа'},
        {id: 6, title: 'Велюр'},
        {id: 7, title: 'Люк'},
      ],
    }
  },
  methods: {
    async onSubmit() {
      const code = await promptModal(Contact);
      if (code) {
        let data = {
          'service_id': 1,
          'form_place': 2,
          'description': 'Запрос на подбор автомобиля',
          'car_brand': this.carBrandSelected,
          'car_model': this.carModelSelected,
          'car_type': this.carTypeSelected,
          'car_body': this.carBodySelected,
          'car_engine': this.carEngineSelected,
          'car_transmission': this.carTransmissionSelected,
          'car_drive': this.carDriveSelected,
          'car_mileage': this.carMileageSelected,
          'car_owners': this.carOwnersSelected,
          'car_price_to': this.carPriceTo,
          'car_capacity_from': this.carCapacityFromSelected,
          'car_capacity_to': this.carCapacityToSelected,
          'car_defects': this.carDefectsSelected,
          'car_color': this.carColorSelected,
          'car_year_from': this.carYearFromSelected,
          'car_year_to': this.carYearToSelected,
          'comfort_params': this.comfortParamsSelected,
          'safety_params': this.safetyParamsSelected,
          'salon_params': this.salonParamsSelected,
        };
        let requestData = Object.assign({}, data, code);
        let result = await this.sendData('/order-request/create', requestData, 'POST');
        if (result.status === 200) {

        this.carBrandSelected = {};
        this.carModelSelected = {};
        this.carTypeSelected = null;
        this.carBodySelected = null;
        this.carEngineSelected = null;
        this.carTransmissionSelected = null;
        this.carDriveSelected = null;
        this.carMileageSelected = null;
        this.carOwnersSelected = null;
        this.carPriceTo = '';
        this.carCapacityFromSelected = null;
        this.carCapacityToSelected = null;
        this.carDefectsSelected = null;
        this.carColorSelected = null;
        this.carYearFromSelected = null;
        this.carYearToSelected = null;
        this.carSelectionMore = false;
        this.comfortParamsSelected = [];
        this.safetyParamsSelected = [];
        this.salonParamsSelected = [];
          
          await openModal(Thanks);
          setTimeout(() => {
            closeModal();
          }, 5000)
        }
      }
    },

    async sendData(url, data, method) {

      return axios({
        method: method,
        url: API_URL + url,
        data: data
      }).then((response) => {
        return response;
      })
    },

    async getCars() {

      return axios({
        method: 'get',
        url: API_URL + '/cars',
      }).then((response) => {
        this.getCarsFromJson(response.data)
      })
    },


    // Генерируем года
    yearsGenerator() {
      for (let i = 2024; i >= 1995; i--) {
        let year = {id: i, text: i + ' г.'};
        this.carYearOptions.push(year)
      }
    },

    // Генерируем объем двигателя
    capacityGenerator() {
      for (let i = 1; i <= 5; i += 0.2) {
        let capacity = {id: i.toFixed(1), text: i.toFixed(1) + ' л.'};
        this.carCapacityOptions.push(capacity);
      }
    },

    // Бренды из Json
    getCarsFromJson(json) {
      this.carsBase = json;
      json.forEach((brand) => {
        brand = {
          id: brand.id,
          text: brand.name
        };
        this.carBrandsOptions.push(brand)
      });
    },

    // Модели на основе Бренда
    loadModels(id) {
      this.carModelOptions[id] = [];
      let selected = this.carBrandSelected[id];
      const brand = this.carsBase.find((brand) => brand.id === parseInt(selected));

      brand.models.forEach((model) => {
        let models = {
          id: model.id,
          text: model.name
        };
        this.carModelOptions[id].push(models)
      });
    },

    // Добавляем компонент select2 по кнопке
    addSelect(id) {
      let select2component = {id: id};
      this.select2components.push(select2component);
    },

    // Удаляем компонент
    removeSelect(id) {
      const index = this.select2components.findIndex(n => n.id === id);
      if (index !== -1) {
        this.select2components.splice(index, 1);
      }
    },

    // Фильтр значений при выборе года
    filteredYears() {
      if (this.carYearFromSelected) {
        return this.carYearOptions.filter((data) => data.id >= this.carYearFromSelected)
            .map((data) => {
              return {id: data.id, text: data.text};
            });
      }
      return this.carYearOptions;
    },

    // Фильтр значений при выборе объема двигателя
    filteredCapacity() {
      if (this.carCapacityFromSelected) {
        return this.carCapacityOptions.filter((data) => data.id >= this.carCapacityFromSelected)
            .map((data) => {
              return {id: data.id, text: data.text};
            });
      }
      return this.carCapacityOptions;
    },
  },
}
</script>

<style lang="scss" scoped>

</style>