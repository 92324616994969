<template>
  <div class="request-container">
    <div class="container">
      <section id="request" class="request">
        <h1 class="text-center" data-aos="fade-up">Оставьте заявку на подбор авто</h1>
        <div class="request__body">
          <div class="request__car" data-aos="fade-right"></div>
          <div class="request__form" data-aos="fade-left">
            <a class="btn  btn--uppercase btn--lg" href="#carselection"><span>Оставить заявку</span></a>
            <a @click="$emit('my-event')" @click.prevent class="faq-link request__faq-link" href="#">Часто задаваемые вопросы</a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

