<template>    <!-- Мобильное меню -->
  <MobileMenu :headerMenu="headerMenu"></MobileMenu>
  <header>
    <div class="container">
      <div class="header__body">
        <div class="header__burger">
          <!-- Кнопка мобильного меню -->
          <a href="#" class="mobile_menu">
            <img src="/dist/images/menu.svg" width="30" height="30" alt="Меню">
          </a>
        </div>
        <div class="header__logo">
          <img src="/dist/images/logo.png" alt="CarPickUp - Онлайн-сервис оценки и подбора автомобилей с пробегом"
               class="header__logo-image">
          <p class="header__logo-subtitle">проверка и подбор авто</p>
        </div>
        <nav class="header__menu">
          <button @click="showPrices" class="header__link">Цены</button>
          <a v-for="menuItem in headerMenu" :key="menuItem.id" v-html="menuItem.title" :href="menuItem.href" @click="menuItem.action" class="header__link"></a>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import {closeModal, container, openModal, promptModal} from "jenesius-vue-modal";
import PricesForm from "../modals/prices.vue";
import MobileMenu from "../blocks/mobileMenu.vue";
import Thanks from "../modals/thanks.vue";
import axios from "axios";
import {API_URL} from "../app";
export default {
  data() {
    return {
      headerMenu: [
        {href: '#ourservice', title: 'Услуги', action: ''},
        {href: '#whatwecheck', title: 'Гарантия', action: ''},
        {href: '#reviews', title: 'Отзывы', action: ''},
        {href: '#youtube', title: "Мы на <span class='header__icon-youtube'></span>", action: ''},
        {href: '#checkedcars', title: 'Осмотренные авто', action: ''},
      ]
    }
  },
  components: {
    PricesForm, Thanks,MobileMenu
  },
  methods: {
    async showPrices() {
      const code = await promptModal(PricesForm);
      const view = document.getElementById("whatwecheck");
      const selection = document.getElementById("carselection");
      if (code.service === 1) {
        this.$smoothScroll({
          scrollTo: selection, // scrollTo is also allowed to be number
        })
      }
      if (code.service === 2) {
        this.$smoothScroll({
          scrollTo: view, // scrollTo is also allowed to be number
        })
      }
    },

    async sendData(url, data, method) {

      return axios({
        method: method,
        url: API_URL + url,
        data: data
      }).then((response) => {
        return response;
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>