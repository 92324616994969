<template>
  <form @submit.prevent>
    <h1>Наши цены на подбор</h1>
    <p>Для расчета стоимости подбора, укажите цену автомобиля</p>


    <div class="calculation">
      <strong id="calculation--title">Укажите цену автомобиля</strong>
      <CurrencyInput
          placeholder="Пример: 900 000"
          v-model="carPrice"
          class="input-lg"
          @keyup="calcServicePrice"
          :options="{
                                      currency: 'RUB',
                                      hideCurrencySymbolOnFocus: false,
                                      hideGroupingSeparatorOnFocus: false,
                                      hideNegligibleDecimalDigitsOnFocus: false,}"
      />

    </div>

    <div v-show="carPrice" class="calculation-result text-center">
      <h2 class="calculation-result--title">Стоимость подбора автомобиля</h2>
      <div id="car-select-price">Ваша стоимость подбора составляет от: <strong class="pink">{{ carSelectionPrice }}</strong> ₽</div>
    </div>
    <div class="tingle-modal-box__footer text-center">
      <button @click='handleClick' class="btn btn--uppercase btn--lg btn--blue">Подробнее об услуге</button>
    </div>
  </form>
</template>

<script>
import {Modal} from "jenesius-vue-modal";
import CurrencyInput from "../components/CurrencyInput.vue";
import {API_URL} from "../app";
let base = [
  {min: null, max: 999999, price: 6900},
  {min: 1000000, max: 1999999, price: 9900},
  {min: 2000000, max: 2999999, price: 14900},
  {min: 3000000, max: 4999999, price: 19900},
  {min: 5000000, max: null, price: 24900},
];

export default {
  components: {CurrencyInput},

  data() {
    return {
      service: 1,
      carPrice: '',
      carSelectionBasePrice: 20000,
      carSelectionPrice: 0
    }
  },
  methods: {
    calcServicePrice() {
      if (this.carPrice) {
        for (let i = 0; i < base.length; i++) {
          if ((base[i].min === null || this.carPrice >= base[i].min) && (base[i].max === null || this.carPrice <= base[i].max)) {
            this.carSelectionPrice = base[i].price + this.carSelectionBasePrice;
          }
        }
      }
    },
    handleClick() {
      let data = {
        service: parseInt(this.service),
      };
      this.$emit(Modal.EVENT_PROMPT, data);
    }
  }
}
</script>

<style lang="scss" scoped>

</style>