<template>
  <div>
    <h1>Ваша заявка успешно отправлена.</h1>
    <p>Эксперт свяжется с Вами в ближайшее время.</p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>