<template>
  <div class="whatif-container">
    <div class="container">
      <section id="whatif" class="whatif">
        <h1 class="text-center" data-aos="fade-up">Если ...</h1>
        <div class="whatif__blocks">
          <div class="whatif__block" data-aos="zoom-in-up" v-for="item in whatIf" :key="item.id">
            <p :class="item.class">{{ item.title }}</p>
          </div>
        </div>
        <h2 class="whatif__subtitle">
          <div class="carpickup" style="width: 150px;"></div>
          - решит эти проблемы
        </h2>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      whatIf: [
        {title: 'не хотите тратить время на поездки и осмотры', class: 'pink'},
        {title: 'не уверены, что сможете оценить состояние атомобиля', class: 'blue'},
        {title: 'опасаетесь недобросовестных продавцов', class: 'pink'},
        {title: 'хотите с минимальными затратами и гарантией выбрать надежный автомобиль', class: 'blue'},
      ]
    }
  },
}
</script>

<style lang="scss" scoped>

</style>