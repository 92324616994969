<template>
  <HeaderBlock/>
  <main>
    <WelcomeBlock/>
    <WhoweareBlock/>
    <RequestBlock @my-event="faq"></RequestBlock>
    <WhatCheckBlock @my-event="faq"></WhatCheckBlock>
    <ReportBlock/>
    <WhatIfBlock/>
    <CarSelectionBlock @my-event="faq"></CarSelectionBlock>
    <HowItWorksBlock/>
    <OurServiceBlock/>
    <ChekedCarsBlock/>
    <ReviewsBlock/>
    <div class="splitter"></div>
    <YouTubeBlock/>
    <LastRequestBlock/>
    <FooterBlock/>
    <MobileMenu/>
    <widget-container-modal/>
  </main>

</template>

<script>
import HeaderBlock from "./blocks/HeaderBlock.vue"
import WelcomeBlock from "./blocks/WelcomeBlock.vue"
import WhoweareBlock from "./blocks/WhoweareBlock.vue"
import StatBlock from "./blocks/StatBlock.vue"
import RequestBlock from "./blocks/RequestBlock.vue"
import WhatCheckBlock from "./blocks/WhatCheckBlock.vue"
import ReportBlock from "./blocks/ReportBlock.vue"
import WhatIfBlock from "./blocks/WhatIfBlock.vue"
import CarSelectionBlock from "./blocks/CarSelectionBlock.vue"
import HowItWorksBlock from "./blocks/HowItWorksBlock.vue"
import OurServiceBlock from "./blocks/OurServiceBlock.vue"
import ChekedCarsBlock from "./blocks/ChekedCarsBlock.vue"
import ReviewsBlock from "./blocks/ReviewsBlock.vue"
import YouTubeBlock from "./blocks/YouTubeBlock.vue"
import LastRequestBlock from "./blocks/LastRequestBlock.vue"
import FooterBlock from "./blocks/FooterBlock.vue"
import Faq from "./modals/faq.vue";
import {container, openModal} from "jenesius-vue-modal";
// import MobileMenu from "./blocks/mobileMenu.vue";


export default {

  components: {
    // MobileMenu,
    HeaderBlock,
    WelcomeBlock,
    WhoweareBlock,
    StatBlock,
    RequestBlock,
    WhatCheckBlock,
    ReportBlock,
    WhatIfBlock,
    CarSelectionBlock,
    HowItWorksBlock,
    OurServiceBlock,
    ChekedCarsBlock,
    ReviewsBlock,
    YouTubeBlock,
    LastRequestBlock,
    FooterBlock,
    Faq,
    WidgetContainerModal: container,
  },
  methods: {
    async faq() {
      const code = await openModal(Faq);
    }
  },
}


</script>
