<template>
  <div class="lastrequest-container">
    <div class="container">
      <section id="lastrequest" class="lastrequest">
        <div class="left" data-aos="fade-right">
          <h1 class="lastrequest__title">
            <span class="carpickup"></span>
            - всегда <br>
            <div class="shift"></div>
            правильный выбор!
          </h1>
        </div>
        <div class="right" data-aos="zoom-in-left">
          <div class="content">
            <h2>Оставьте заявку</h2>
            <p>Мы свяжемся и проконсультируем вас по подбору интересующего автомобиля</p>
            <form @submit.prevent="onSubmit">

              <input
                  pattern="[А-я]{2,100}"
                  type="text"
                  placeholder="Ваше имя"
                  v-model="name"
                  :class="{'error':this.check && !this.name}"
              >

              <input type="text"
                     placeholder="Укажите контактный телефон"
                     v-model="phone"

                     :class="{'error':this.check && !this.phone}"
              >

              <input type="email" placeholder="E-mail"
                     v-model="email"
                     :class="{'error':this.check && !this.email}"
              >

              <button type="submit" class="btn btn--uppercase btn--lg btn--blue lastrequest__btn"
                      href=""><span>Отправить заявку</span></button>
            </form>
          </div>

        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {closeModal, Modal, openModal, promptModal} from "jenesius-vue-modal";
import Contact from "../modals/request.vue";
import Thanks from "../modals/thanks.vue";
import axios from "axios";
import {API_URL} from "../app";
export default {
  data() {
    return {
      check: false,
      name: '',
      phone: '',
      email: '',
    }
  },

  methods: {
    checkForm: function (e) {
      if (this.name && this.phone && this.email) {
        return true;
      }
    },
    async onSubmit() {
      this.check = true;
      console.log('Submit')
      if (this.checkForm()) {
        console.log('Cheked')
        let data = {
          'service_id': 3,
          'form_place': 5,
          'description': 'Заявка внизу сайта',
          'name': this.name,
          'phone': this.phone,
          'email': this.email,
        };

        let result = await this.sendData('/order-request/create', data, 'POST');
        if (result.status === 200) {
          this.name = '';
          this.phone = '';
          this.email = '';

          await openModal(Thanks);
          setTimeout(() => {
            closeModal();
          }, 5000)
        }
      }

    },
    async sendData(url, data, method) {

      return axios({
        method: method,
        url: API_URL + url,
        data: data
      }).then((response) => {
        return response;
      })
    },
  },

}
</script>

<style lang="scss" scoped>
.error {
  border-color: #dc3545;
}
</style>