<template>
  <div class="welcome-container">
    <div class="container">
      <section class="welcome">
        <div class="left" data-aos="fade-right">
          <div class="welcome-text">
            <h1>Ищите авто с пробегом?</h1>
            <h2>
              <span class="carpickup" style="width: 130px"></span>
              - поможет сделать правильный выбор!
            </h2>
            <form @submit.prevent="onSubmit">
              <button type="submit" class="btn  btn--uppercase btn--lg btn--pink"><span>Оставить заявку</span></button>
            </form>
          </div>
        </div>
        <div class="right" data-aos="fade-left">
          <img src="dist/images/header-cars.png">
        </div>
      </section>
    </div>
  </div>

</template>

<script>

import {container, promptModal, openModal, closeModal, container as WidgetContainerModal} from "jenesius-vue-modal";
import RequestForm from "../modals/request.vue"
import Thanks from "../modals/thanks.vue"
import axios from "axios";
import {API_URL} from "../app";

export default {

  components: {
    RequestForm, Thanks,
  },
  methods: {
    async onSubmit() {
      const code = await promptModal(RequestForm, {descriptionShow: true, formPlace: 'Первый блок'});
      if (code) {
        let data = {
          'service_id': 3,
          'form_place': 1
        };
        let requestData = Object.assign({}, data, code);
        let result = await this.sendData('/order-request/create', requestData, 'POST');
        if (result.status === 200) {
          await openModal(Thanks);
          setTimeout(() => {
            closeModal();
          }, 5000)
        }
      }
    },

    async sendData(url, data, method) {
      return axios({
        method: method,
        url: API_URL + url,
        data: data
      }).then((response) => {
        return response;
      })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>