import {createApp} from 'vue'
import App from './App.vue'
import VueSmoothScroll from 'vue3-smooth-scroll'
import AOS from 'aos'
import 'aos/dist/aos.css'
import "./common.scss";

/**
 * API URL
 * @type {string}
 */
export const API_URL = 'https://api.carpickup.ru';
export const app = createApp(App)
app.AOS = new AOS.init();
app.use(AOS);
app.use(VueSmoothScroll);
app.mount('#app')



