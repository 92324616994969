<template>
  <div class="report-container">
    <div class="container">
      <section id="report" class="report">
        <div class="left" data-aos="fade-right">
          <h1>ПРИМЕР ОТЧЕТА <br>ОСМОТРА АВТОМОБИЛЯ</h1>
          <a @click="onClick" @click.prevent href="#" class="btn btn--uppercase btn--lg btn--pink btn-pink">
            <img src="dist/images/file-text.svg" alt="">
            <span>Запросить пример отчета</span>
          </a>
        </div>
        <div class="right" data-aos="zoom-in-left">
          <img src="dist/images/report.png" alt="">
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import {closeModal, openModal, promptModal} from "jenesius-vue-modal";
import Report from "../modals/report.vue";
import Thanks from "../modals/thanks.vue";
import axios from "axios";
import {API_URL} from "../app";

export default {
  methods: {
    async onClick() {
      const code = await promptModal(Report, {descriptionShow: true});
      if (code) {
        let data = {
          'service_id': 5,
          'form_place': 4
        };
        let requestData = Object.assign({}, data, code);
        let result = await this.sendData('/order-request/create', requestData, 'POST');
        if (result.status === 200) {
          await openModal(Thanks);
          setTimeout(() => {
            closeModal();
          }, 5000)
        }
      }
    },
    async sendData(url, data, method) {

      return axios({
        method: method,
        url: API_URL + url,
        data: data
      }).then((response) => {
        return response;
      })
    }
  },
}
</script>

<style lang="scss" scoped>

</style>